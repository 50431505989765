<script setup lang='ts'>
import { imageTransform } from '@qctsw/common'
import { logicHomeCase } from '@/logic/home/case'

const {
  state,
  activeCaseTab,
  caseTabs,
  getCaseData,
} = logicHomeCase()

await getCaseData()
</script>

<template>
  <n-tabs v-model:value="activeCaseTab" type="line" animated>
    <n-tab-pane
      v-for="v, i in caseTabs" :key="i" :name="i" :tab="v.name"
      :style="{ '--n-tab-padding': '0 0 0.25rem 0' }"
    >
      <UtilLoadState common-class="h-75" :state="state" client-auto-reload :reload-handler="getCaseData">
        <div class="flex overflow-hidden">
          <NuxtLink
            v-if="v.banner"
            :to="v.path(v.banner.id)"
            :title="v.banner.title"
            class="w-105 h-65 opacity-61 rounded-md relative overflow-hidden flex-shrink-0"
          >
            <img loading="lazy" :src="imageTransform(v.banner.imgUrl, { width: 420, height: 260 })">
            <span class="bg-black bg-opacity-40 text-white absolute-0 flex items-center justify-center text-8 font-bold">
              <span>#</span>
              <span class="truncate max-w-4/5 inline-block">{{ v.banner.title }}</span>
              <span>#</span>
            </span>
          </NuxtLink>
          <div class="flex-grow min-w-0 pl-sm">
            <NuxtLink
              v-for="item, index in v.list" :key="index"
              class="block truncate hover:text-orange-6 mb-1.5 last:mb-0"
              :to="v.path(item.id)"
            >
              {{ item.title }}
            </NuxtLink>
          </div>
        </div>
      </UtilLoadState>
    </n-tab-pane>
  </n-tabs>
</template>

<style lang='scss' scoped>

</style>
