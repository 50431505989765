<!--
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-01-24 10:05:36
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-02-18 16:59:07
-->
<script setup lang='ts'>
import { APP_ROUTER, imageTransform } from '@qctsw/common'
import { contentRemoveTags, timeFormat } from '@qctsw/utils'
import { LOAD_STATE } from '@/shared/types'
import { logicHomeSurvey } from '@/logic/home/survey'

const {
  activeSurveyTab,
  surveyTabs,
  getSurveyData,
} = logicHomeSurvey()

await getSurveyData()
</script>

<template>
  <n-tabs v-model:value="activeSurveyTab" type="line" animated>
    <n-tab-pane v-for="v, i in surveyTabs" :key="i" :name="i" :tab="v.name">
      <UtilLoadState
        type="up" :length="v.list.length"
        common-class="h-75"
        :state="v.pagination.pagination.status"
        :reload-handler="getSurveyData"
      >
        <div v-if="v.list.length" class="grid grid-cols-2 gap-x-sm gap-y-3">
          <NuxtLink
            v-for="item, index in v.list"
            :key="index" :to="APP_ROUTER.complaintDetail(item.complainId)"
            :title="item.title"
            class="flex"
          >
            <img
              v-if="item.imgUrl"
              loading="lazy"
              :src="imageTransform(item.imgUrl, { width: 154, height: 106 })"
              :alt="item.title"
              class="w-38 h-24 object-cover rounded overflow-hidden flex-shrink-0 mr-sm"
            >
            <span class="flex-grow min-w-0 inline-flex flex-col justify-between">
              <span>
                <span class="line-clamp-1 hover:text-orange-6">
                  <UtilComplaintStatus v-if="!isNaN(Number(item.complainStatusCode)) && i !== 1" :code="Number(item.complainStatusCode)" />
                  <span class="font-bold">{{ item.title }}</span>
                </span>
                <span class="line-clamp-2 mt-1 text-3.5">
                  {{ contentRemoveTags(item.complainContent) }}
                </span>
              </span>
              <UtilContentTips :tips-data="{ date: timeFormat(item.createTime, `YYYY-MM-DD HH:mm:ss`), favorCount: item.goodCount || 0 }" />
            </span>
          </NuxtLink>
        </div>
        <template v-else>
          <BasicEmpty />
        </template>
      </UtilLoadState>

      <BasicLoadButton
        class="bg-slate-2"
        :state="v.pagination.pagination.status"
        @load="() => getSurveyData()"
      />
    </n-tab-pane>
  </n-tabs>
</template>

<style lang='scss' scoped>

</style>
