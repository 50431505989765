<script setup lang='ts'>
import { APP_ROUTER, imageTransform } from '@qctsw/common'
import { logicBrandComplaintRank } from '@/logic/rank/brandComplaint'

const {
  state,
  data,
  getBrandComplaintRank,
} = logicBrandComplaintRank()

await getBrandComplaintRank()
</script>

<template>
  <n-card
    title="厂商投诉" embedded size="small"
    class="rounded-md"
    header-style="--n-title-font-weight: bold;"
  >
    <template #header-extra>
      <span class="text-red-4 text-3.25">近2年内投诉量<span font-bold text-2.5>/</span>宗</span>
    </template>
    <UtilLoadState :state="state" :reload-handler="getBrandComplaintRank">
      <template v-if="data && data.length">
        <NuxtLink
          v-for="(v, i) in data" :key="i"
          class="mb-sm text-3.5 flex items-center last:mb-0"
          :to="APP_ROUTER.complaintSearch({ brandId: v.carBrandId })"
        >
          <span class="rank mr-2.5" :class="`rank__${i}`">{{ i + 1 }}</span>
          <img
            loading="lazy"
            width="36" height="36"
            class="rounded-full overflow-hidden flex-shrink-0 mx-sm bg-transparent"
            :src="imageTransform(v.coverImg)" fit="cover"
          >
          <span class="flex-1 font-bold">
            {{ v.carVendorName }}
          </span>
          <span class="text-red-5 ml-sm text-4 font-mono">
            {{ v.total }}
          </span>
        </NuxtLink>
      </template>
      <BasicEmpty v-else />
    </UtilLoadState>
  </n-card>
</template>

<style lang='scss' scoped>
@import url(@/assets/styles/rank.scss);
</style>
