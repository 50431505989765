import { cityName, provinceName } from '@qctsw/common'
import type { NewCompleteComplaintResponse } from '@/server/types'
import { LOAD_STATE } from '@/shared/types'
import { getNewCompleteComplaintListApi } from '@/server'

export function logicNewCompleteComplaintList() {
  const state = ref<LOAD_STATE>(LOAD_STATE.INIT)
  const list = ref<NewCompleteComplaintResponse[]>([])

  async function getNewCompleteComplaintList() {
    const res = await useLoadState({
      key: `NEW_COMPLETE_COMPLAINT_LIST`,
      state,
      fn: getNewCompleteComplaintListApi,
    })

    list.value = res?.data || []
  }

  function transformArea(v: NewCompleteComplaintResponse) {
    return v.carDealerProvinceId
      ? Array.from(new Set([provinceName(v.carDealerProvinceId, '', true), cityName(v.carDealerCityId, '', true)])).join('')
      : ''
  }

  return {
    state,
    list,
    transformArea,
    getNewCompleteComplaintList,
  }
}
