import { APP_ROUTER } from '@qctsw/common'
import { getCollectiveCaseListApi, getSuccessCaseListApi } from '@/server'
import { LOAD_STATE } from '@/shared/types'
import type { CaseCollectiveResponse, CaseSuccessResponse } from '~/server/types'

export interface CaseTab {
  name: string
  path: (id: number) => string
  banner: {
    id: number
    imgUrl: string
    title: string
  } | null
  list: {
    id: number
    title: string
  }[]
}

export function logicHomeCase() {
  const state = ref<LOAD_STATE>(LOAD_STATE.INIT)
  const activeCaseTab = ref(0)
  const caseTabs = reactive<CaseTab[]>([
    {
      name: '案例征集',
      path: APP_ROUTER.collectiveDetail,
      banner: null,
      list: [],
    },
    {
      name: '成功案例',
      path: APP_ROUTER.complaintDetail,
      banner: null,
      list: [],
    },
  ])

  function format(res: (CaseCollectiveResponse | CaseSuccessResponse)[]) {
    const collectiveList: CaseCollectiveResponse[] = []
    const successList: CaseSuccessResponse[] = []
    res.forEach((item) => {
      if ('collectiveId' in item)
        collectiveList.push(item)
      else
        successList.push(item)
    })

    if (collectiveList.length) {
      caseTabs[0].banner = null
      for (const item of collectiveList) {
        if (item.complainImgUrls && item.complainImgUrls.length > 0 && !caseTabs[0].banner) {
          caseTabs[0].banner = {
            id: item.collectiveId,
            imgUrl: item.complainImgUrls[0],
            title: item.collectiveTitle,
          }
          continue
        }
        caseTabs[0].list.push({
          id: item.collectiveId,
          title: item.collectiveTitle,
        })
      }
    }
    else if (successList.length) {
      caseTabs[1].banner = null
      for (const item of successList) {
        if (item.imgUrl && !caseTabs[1].banner) {
          caseTabs[1].banner = {
            id: item.complainId,
            imgUrl: item.imgUrl,
            title: item.complainTitle,
          }
          continue
        }
        caseTabs[1].list.push({
          id: item.complainId,
          title: item.complainTitle,
        })
      }
    }
  }

  async function getCaseData() {
    const fn = activeCaseTab.value === 0 ? getCollectiveCaseListApi : getSuccessCaseListApi
    const res = await useLoadState<(CaseCollectiveResponse | CaseSuccessResponse)[]>({
      key: `HOME_CASE_DATA_${activeCaseTab.value}`,
      state,
      fn,
    })

    if (res?.data)
      format(res.data)
  }

  useActivatedWatch(() => [
    watch(activeCaseTab, () => {
      if (caseTabs[activeCaseTab.value].list.length === 0)
        getCaseData()
    }),
  ])

  return {
    state,
    activeCaseTab,
    caseTabs,
    getCaseData,
  }
}
