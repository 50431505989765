<!--
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-01-24 10:05:36
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-02-22 10:54:26
 * @FilePath: \qctsw-vue\packages\app\src\pages\index\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script lang="ts" setup>
import { APP_ROUTER } from '@qctsw/common'
import Series from './components/Series.vue'
import Banner from './components/Banner.vue'
import Complaint from './components/Complaint.vue'
import MediaComplaint from './components/MediaComplaint.vue'
import WeekRedBlack from './components/WeekRedBlack.vue'
import News from './components/News.vue'
import { HOME_SITE_META } from '@/shared/site'
import { AD_KEY } from '~/server/types'

useSite(HOME_SITE_META)
</script>

<template>
  <div class="public-container public-page relative">
    <!-- TODO:A1 -->

    <BaseAd :ad-key="AD_KEY.A1" />
    <!-- TODO:左右广告A2,A3 -->
    <BaseAd :ad-key="AD_KEY.A2" img-class="fixed w-120px" body-class="w-120px absolute top-6 -left-150px z-1 overflow-auto " />
    <BaseAd :ad-key="AD_KEY.A3" img-class="fixed w-120px" body-class="w-120px overflow-auto absolute top-6 -right-150px z-1" />
    <Series />
    <Banner />
    <div class="shadow border-1 border-gray-1 flex items-center mt-6 py-4 rounded">
      <BasicStepComplaint class="pl-5 pr-5 flex-grow min-w-0" />
      <NuxtLink
        class="mx-5 bg-orange-6 text-white px-4xl py-2.5 rounded-full text-5.5 hover:bg-orange-5"
        :to="APP_ROUTER.publish()"
      >
        我要投诉
      </NuxtLink>
    </div>
    <BaseAd :ad-key="AD_KEY.A6" />
    <!-- TODO:A6 -->
    <Complaint class="mb-2xl" />
    <!-- TODO:A8 -->
    <BaseAd :ad-key="AD_KEY.A8" />
    <MediaComplaint class="mb-2xl" type="video" title="视频投诉" />
    <MediaComplaint class="mb-2xl" />
    <WeekRedBlack class="mb-2xl" />
    <BasicTitle class="mb-2.5" title="资讯" />
    <News class="mb-2xl" />
  </div>
</template>
