import { isNumber } from '@qctsw/utils'
import { getSurveyComplaintPageApi } from '@/server'
import { LOAD_STATE } from '@/shared/types'
import type { SurveyComplaintPageResponse } from '~/server/types'
import type { PaginationFn } from '@/composables/usePagination'
import { usePagination } from '@/composables/usePagination'

const baseConfig = {
  pageSize: 10, // 暂时不生效，后端固定6
  type: 'up',
} as const
export function logicHomeSurvey() {
  const state = ref<LOAD_STATE>(LOAD_STATE.INIT)
  const activeSurveyTab = ref(0)
  const surveyTabs = reactive<{
    name: string
    list: SurveyComplaintPageResponse[]
    pagination: PaginationFn
  }[]>([
    {
      name: '一追到底',
      list: [],
      pagination: usePagination({
        ...baseConfig,
        key: `一追到底`,
        request: params => getSurveyComplaintPageApi({ ...params, selectTypeCode: 1 }),
      }),
    },
    {
      name: '已结案',
      list: [],
      pagination: usePagination({
        ...baseConfig,
        key: `已结案`,
        request: params => getSurveyComplaintPageApi({ ...params, selectTypeCode: 2 }),
      }),
    },
  ])

  async function getSurveyData(tab?: number) {
    if (tab !== undefined && isNumber(tab))
      activeSurveyTab.value = tab

    const currentPagination = surveyTabs[activeSurveyTab.value].pagination
    const { list } = await currentPagination.loadPaginationData() || {}
    if (!list)
      return
    surveyTabs[activeSurveyTab.value].list.push(...list)
  }

  useActivatedWatch(() => [
    watch(activeSurveyTab, () => {
      if (surveyTabs[activeSurveyTab.value].list.length === 0)
        getSurveyData()
    }),
  ])
  return {
    state,
    activeSurveyTab,
    surveyTabs,
    getSurveyData,
  }
}
