import type { HomeBannerArticles, HomeBannerComplaint, HomeBannerData } from '@/server/types'
import { LOAD_STATE } from '@/shared/types'
import { getHomeBannerDataApi } from '@/server'

export function logicHomeBanner() {
  const state = ref<LOAD_STATE>(LOAD_STATE.INIT)
  const bannerData = ref<{
    banner: HomeBannerArticles[]
    news: HomeBannerArticles[]
    complaint: HomeBannerComplaint
  }>({
    banner: [],
    news: [],
    complaint: {
      complainTotal: 0,
      dayTotalComplain: 0,
      totalCollective: 0,
      totalMore: 0,
      exchangeCarTotal: 0,
    },
  })

  function formatBannerData(data?: HomeBannerData) {
    if (!data)
      return
    const banner = data.bannres
    let news = []
    for (const item in data.articles)
      news.push(data.articles[item])

    news = news.slice(0, 12)
    const complaint = data.complain
    bannerData.value = {
      banner,
      news,
      complaint,
    }
  }

  async function getHomeBannerData() {
    const res = await useLoadState({
      key: `HOME_BANNER`,
      state,
      fn: getHomeBannerDataApi,
    })

    formatBannerData(res?.data)
  }

  return {
    state,
    bannerData,
    getHomeBannerData,
  }
}
