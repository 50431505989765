import { LOAD_STATE } from '@/shared/types'
import type { FactoryRankResponse } from '@/server/types'
import { getFactoryRankApi } from '@/server'

export function logicFactoryRank() {
  const labelList = ['回复率', '综合评分']
  const codeList = ['REPLY', 'SCORE'] as const
  const active = ref<number>(0)

  const data = reactive<{
    loadState: LOAD_STATE
    list: FactoryRankResponse[]
  }[]>(labelList.map(_ => ({
    loadState: LOAD_STATE.INIT,
    list: [],
  })))

  async function getRankData() {
    const res = await useLoadState({
      state: (state) => { data[active.value].loadState = state },
      key: `厂商排行排行榜-${codeList[active.value]}`,
      onlyClient: true,
      fn: () => getFactoryRankApi(codeList[active.value]),
    })

    if (res && res.data)
      data[active.value].list = res.data
  }

  useActivatedWatch(() => [
    watch(active, () => {
      if (data[active.value].list.length === 0)
        getRankData()
    }),
  ])
  return {
    data,
    labelList,
    active,
    getRankData,
  }
}
