<script setup lang='ts'>
import { APP_ROUTER, checkLink, imageTransform } from '@qctsw/common'
import { contentRemoveTags, timeFormat } from '@qctsw/utils'
import { logicHomeNewsBanner, logicHomeNewsPage } from '@/logic/home/news'

const {
  state: bannerState,
  banner,
  getBanner,
} = logicHomeNewsBanner()

const {
  state,
  data,
  getNewsPage,
} = logicHomeNewsPage()
const bannerNewsList = computed(() => {
  return data.value.slice(0, 10) ?? []
})
const newsList = computed(() => {
  return data.value.slice(10) ?? []
})

await Promise.all([getBanner(), getNewsPage()])
</script>

<template>
  <div class="flex">
    <div class="flex-grow min-w-205">
      <div class="flex items-start mb-sm">
        <UtilLoadState :state="bannerState" client-auto-reload :reload-handler="getBanner">
          <NCarousel
            show-arrow dot-type="line" effect="fade"
            :default-index="0" :autoplay="true"
            class="flex-shrink-0 w-126 max-h-70 aspect-16/9 rounded-md mr-sm"
          >
            <NuxtLink
              v-for="item, index in banner" :key="index"
              class="flex items-center justify-end h-full relative"
              :to="checkLink(item.link, APP_ROUTER.newsDetail(item.id))"
            >
              <img
                class="h-full w-full object-cover"
                :alt="item.title"
                :src="imageTransform(item.coverImgUrl, { width: 975, height: 540 })"
              >
              <h3 class="title">
                {{ item.title }}
              </h3>
            </NuxtLink>
          </NCarousel>
        </UtilLoadState>
        <UtilLoadState :state="state" client-auto-reload :reload-handler="getNewsPage">
          <div class="flex-grow min-w-0 text-4">
            <NuxtLink
              v-for="(v, i) in bannerNewsList" :key="i"
              :to=" APP_ROUTER.newsDetail(v.articleId)"
              class="block truncate mb-1.25 last:mb-0 hover:text-orange-6 hover:text-orange-6)"
            >
              {{ v.title }}
            </NuxtLink>
          </div>
        </UtilLoadState>
      </div>
      <template v-if="newsList?.length">
        <div
          v-for="item, index in newsList"
          :key="index" :to="APP_ROUTER.newsDetail(item.articleId)"
          :title="item.title"
          class="flex mb-sm"
        >
          <NuxtLink
            :to="APP_ROUTER.newsDetail(item.articleId)"
            class="inline-block w-52 aspect-16/9 max-h-29.25 object-cover rounded overflow-hidden flex-shrink-0 mr-sm"
          >
            <img
              v-if="item.coverImgUrl"
              loading="lazy"
              :src="imageTransform(item.coverImgUrl, { width: 208, height: 117 })"
              :alt="item.title"
              class="w-full h-full"
            >
          </NuxtLink>
          <span class="flex-grow min-w-0 inline-flex flex-col justify-between">
            <NuxtLink :to="APP_ROUTER.newsDetail(item.articleId)">
              <span class="line-clamp-1 hover:text-orange-6">
                <span class="font-bold">{{ item.title }}</span>
              </span>
              <span class="line-clamp-3 mt-1 text-3.5 text-gray-5">
                {{ contentRemoveTags(item.content) }}
              </span>
            </NuxtLink>
            <UtilContentTips
              :author-id="item.authorId"
              :tips-data="{
                date: timeFormat(item.createTime),
                author: item.authorName,
                lookCount: item.lookCount ?? 0,
                commentCount: item.commentCount ?? 0,
                favorCount: item.goodCount || 0,
              }"
            />
          </span>
        </div>
        <BasicLoadButton class="bg-slate-2" :state="state" @load="() => getNewsPage()" />
      </template>
    </div>
    <div class="flex-shrink-0 w-[calc(100%-53rem)] pl-xl">
      <ClientOnly>
        <RankCctv class="mb-sm" />
        <RankHotNews class="sticky top-25" />
      </ClientOnly>
    </div>
  </div>
</template>

<style lang='scss' scoped>
@import url(./banner.scss);
</style>
