import { usePagination } from '@/composables/usePagination'
import { getHomeNewsBannerDataApi, getNewsPageApi } from '@/server'
import type { HomeNewsBannerData, HomeNewsPageResponse } from '@/server/types'
import { LOAD_STATE } from '~/shared/types'

export function logicHomeNewsBanner() {
  const state = ref(LOAD_STATE.INIT)
  const banner = ref<HomeNewsBannerData[]>([])
  async function getBanner() {
    const res = await useLoadState({
      state,
      key: '首页资讯轮播',
      fn: getHomeNewsBannerDataApi,
    })

    if (res && res.data)
      banner.value = res.data
  }

  return {
    state,
    banner,
    getBanner,
  }
}
export function logicHomeNewsPage() {
  const data = ref<HomeNewsPageResponse[]>([])
  const pagination = usePagination({
    key: '最新资讯',
    request: params => getNewsPageApi(params),
  })
  async function getNewsPage() {
    const { list } = await pagination.loadPaginationData() || {}
    if (!list)
      return
    data.value.push(...list)
  }

  return {
    data,
    pagination,
    getNewsPage,
    state: computed(() => pagination.pagination.status),
  }
}
