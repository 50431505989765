import type { MediaComplaintPageResponse } from '@/server/types'
import { getPictureComplaintPageApi, getVideoComplaintPageApi } from '@/server'
import { usePagination } from '@/composables/usePagination'
import type { PaginationParams } from '~/server/request/types'

export function logicHomeMediaComplaint<T extends 'video' | 'picture'>(type: T) {
  const data = ref<(MediaComplaintPageResponse<T>)[]>([])

  function fn(params: PaginationParams) {
    if (type === 'video')
      return getVideoComplaintPageApi(params)
    else
      return getPictureComplaintPageApi(params)
  }
  const pagination = usePagination({
    key: `${type === 'picture' ? '图片投诉' : '视频投诉'}`,
    pageSize: 7,
    request: fn as any,
  })
  async function getMediaComplaintList() {
    const { list } = await pagination.loadPaginationData(1) || {}

    data.value = (list || [])
  }

  return {
    state: computed(() => pagination.pagination.status),
    pagination,
    list: data,
    getMediaComplaintList,
  }
}
