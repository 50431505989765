<script setup lang='ts'>
import { APP_ROUTER, addHost, imageTransform } from '@qctsw/common'
import { logicHomeMediaComplaint } from '@/logic/home/mediaComplaint'

const props = withDefaults(defineProps<{
  type?: 'video' | 'picture'
  title?: string
}>(), {
  type: 'picture',
  title: '图片投诉',
})
const {
  state,
  list,
  getMediaComplaintList,
} = logicHomeMediaComplaint(props.type)

await getMediaComplaintList()
</script>

<template>
  <div>
    <BasicTitle :title="props.title" class="mb-2.5" />
    <UtilLoadState :state="state" :reload-handler="getMediaComplaintList">
      <div class="grid grid-cols-5 grid-rows-2 gap-4 media-container h-75">
        <NuxtLink
          v-for="(v, i) in (list as any)" :key="i"
          :to="APP_ROUTER.complaintDetail(v.complainId)"
          class="rounded-1.5 overflow-hidden relative video-item"
        >
          <video v-if="type === 'video' && !v.videoCoverUrl && v.videoUrl" :src="addHost(v.videoUrl) ?? ''" />
          <img
            v-else
            class="w-full h-full object-cover"
            :src="imageTransform(type === 'video' ? v.videoCoverUrl : addHost(v.imgUrl), { width: 470, height: 300 })"
            :alt="v.title" :path="APP_ROUTER.complaintDetail(v.complainId)"
          >
          <i v-if="type === 'video'" class="i-ion:play play" :class="[i !== 0 ? 'text-8' : 'text-12']" />
          <h4 class="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black  truncate text-white px-sm py-2">
            {{ v.title }}
          </h4>
        </NuxtLink>
      </div>
    </UtilLoadState>
  </div>
</template>

<style lang='scss' scoped>
.media {
  &-container {
    video {
        --uno: w-full h-full bg-black;
    }

    a:nth-child(1) {
      --uno: col-span-2 row-span-2;
    }
  }

  &-item {
    --uno:cursor-pointer;
  }
}
.play {
  --uno: text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-1 pointer-events-none select-none;
}
</style>
