import { LOAD_STATE } from '@/shared/types'
import type { BrandComplaintRankResponse } from '@/server/types'
import { getBrandComplaintRankApi } from '@/server'

export function logicBrandComplaintRank() {
  const state = ref(LOAD_STATE.INIT)

  const data = ref<BrandComplaintRankResponse[]>([])
  async function getBrandComplaintRank() {
    const res = await useLoadState({
      state,
      key: `厂商投诉排行榜`,
      onlyClient: true,
      fn: getBrandComplaintRankApi,
    })

    if (res && res.data)
      data.value = res.data
  }

  return {
    state,
    data,
    getBrandComplaintRank,
  }
}
